<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="companyManagementSearchFlag" @click="companyManagementSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="companyManagementAddFlag" @click="companyManagementAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="companyManagementModifyFlag" @click="companyManagementModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="companyManagementDelFlag" @click="companyManagementDel">删除</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入公司名称（模糊匹配）" v-model="AccurateAndUnique_companyName" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="公司高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="100px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="公司编码" prop="companyId">
                                                    <el-input  maxlength="18" v-model="dataForms.companyId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="营业执照号码" prop="license">
                                                    <el-input  maxlength="18" v-model="dataForms.license"></el-input>
                                                </el-form-item>
                                                <el-form-item label="审核状态" prop="verify">
                                                    <el-select v-model="dataForms.verify" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="未审核" value="01"></el-option>
                                                        <el-option label="审核通过" value="02"></el-option>
                                                        <el-option label="审核失败" value="03"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="启用状态" prop="status">
                                                    <el-select v-model="dataForms.status" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="启用" value="1"></el-option>
                                                        <el-option label="冻结" value="0"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="公司分类" prop="companyType">
                                                    <el-select v-model="dataForms.companyType" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="普通用户" value="0"></el-option>
                                                        <el-option label="承运商/司机" value="1"></el-option>
                                                        <el-option label="业务管理员" value="2"></el-option>
                                                    </el-select>
                                                </el-form-item>
<!--                                                <cityLinkageReg @addlist="getCity" :addList="addList"></cityLinkageReg>-->
                                              <CountriesLink @addlist="getCity" :addList="addList"></CountriesLink>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                                                <el-button size="mini"  v-if="companyManagementSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    @handleImgClick="handleImgClick"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog :visible.sync="dialogVisible_imgUrl" :before-close="handleCloseImgDialog">
            <el-image
                    :src="dialogImageUrl"
                    :z-index="3000"
                    :preview-src-list="dialogImageUrl_srcList">
            </el-image>
            <!--<img width="100%" :src="dialogImageUrl" alt="">-->
        </el-dialog>
        <el-dialog
                :title="dialogcompanyManagement_title"
                :visible.sync="dialog_companyManagement"
                width="85%"
                :modal="true"
                center
                :before-close="handleClose_companyManagement">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">公司信息</span>
                </div>
                <div>
                    <el-form :model="companyManagementForm"  ref="companyManagementForm" :rules="companyManagementFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="公司全称" prop="companyName">
                                    <el-input v-model="companyManagementForm.companyName" :disabled="companyManagementFlag[0]"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="营业执照" prop="license">
                                    <el-input v-model="companyManagementForm.license" :disabled="companyManagementFlag[1]"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="公司地址" prop="address">
                                    <el-input v-model="companyManagementForm.address"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="合同编号" prop="contractNo">
                                    <el-input v-model="companyManagementForm.contractNo"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="法人身份证号" prop="identityNo">
                                    <el-input v-model="companyManagementForm.identityNo"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="联系方式" prop="phone">
                                    <el-input v-model="companyManagementForm.phone"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="公司固话" prop="telephone">
                                    <el-input v-model="companyManagementForm.telephone"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="公司状态" prop="status">
                                    <el-select v-model="companyManagementForm.status" placeholder="请选择状态">
                                        <el-option label="启用" value="1"></el-option>
                                        <el-option label="冻结" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="公司类型" prop="companyType">
                                    <el-select v-model="companyManagementForm.companyType" placeholder="请选择公司类型" :disabled="companyManagementFlag[2]">
                                        <el-option label="普通用户" value="0"></el-option>
                                        <el-option label="承运商/司机" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="公司所属模块" prop="moduleId">
                                    <el-select v-model="companyManagementForm.moduleId" placeholder="请选择服务组" :disabled="companyManagementFlag[3]">
                                        <el-option label="城市配" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="揽收标识" prop="receiveFlag">
                                    <el-select v-model="companyManagementForm.receiveFlag" placeholder="请选择服务组">
                                        <el-option label="派车后揽收" value="0"></el-option>
                                        <el-option label="发布后揽收" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <CityLinkageReg @addlist="getCity" :addList="addList"></CityLinkageReg>
<!--                              <CountriesLink @addlist="getCity" :addList="addList"></CountriesLink>-->
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="营业执照附件照片" prop="licenseUrl" class="Reditem">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="imgurl_s"
                                            :z-index="3000"
                                            v-if="imgflagmodel"
                                            :preview-src-list="srcList">
                                    </el-image>
                                    <el-link v-if="imgflagdel" icon="el-icon-delete" @ @click="delimg">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag"
                                            :class="{ hide: hideUp,uoloadSty:showBtnImg,disUoloadSty:noneBtnImg}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess"
                                            :on-error="handleUpError"
                                            :on-change="handleUpUpload"
                                            :on-preview="handleUpPreview"
                                            :on-remove="handleUpRemove">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="合同附件照片" prop="contractUrl">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :z-index="3000"
                                            :src="imgurl_s_contractUrl"
                                            v-if="imgflagmodel1"
                                            :preview-src-list="srcList1">
                                    </el-image>
                                    <el-link v-if="imgflagdel1" icon="el-icon-delete" @ @click="delimg1">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag1"
                                            :class="{ hide: hideUp1,uoloadSty:showBtnImg1,disUoloadSty:noneBtnImg1}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess1"
                                            :on-error="handleUpError1"
                                            :on-change="handleUpUpload1"
                                            :on-preview="handleUpPreview1"
                                            :on-remove="handleUpRemove1">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible1">
                                        <img width="100%" :src="dialogImageUrl1" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="公司法人身份证正面照片" prop="identityPositiveUrl">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :z-index="3000"
                                            :src="imgurl_s_identityPositiveUrl"
                                            v-if="imgflagmodel2"
                                            :preview-src-list="srcList2">
                                    </el-image>
                                    <el-link v-if="imgflagdel2" icon="el-icon-delete" @ @click="delimg2">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag2"
                                            :class="{ hide: hideUp2,uoloadSty:showBtnImg2,disUoloadSty:noneBtnImg2}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess2"
                                            :on-error="handleUpError2"
                                            :on-change="handleUpUpload2"
                                            :on-preview="handleUpPreview2"
                                            :on-remove="handleUpRemove2">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible2">
                                        <img width="100%" :src="dialogImageUrl2" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="公司法人身份证反面照片" prop="identityBackUrl">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :z-index="3000"
                                            :src="imgurl_s_identityBackUrl"
                                            v-if="imgflagmodel3"
                                            :preview-src-list="srcList3">
                                    </el-image>
                                    <el-link v-if="imgflagdel3" icon="el-icon-delete" @ @click="delimg3">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag3"
                                            :class="{ hide: hideUp3,uoloadSty:showBtnImg3,disUoloadSty:noneBtnImg3}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess3"
                                            :on-error="handleUpError3"
                                            :on-change="handleUpUpload3"
                                            :on-preview="handleUpPreview3"
                                            :on-remove="handleUpRemove3">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible3">
                                        <img width="100%" :src="dialogImageUrl3" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_companyManagement">提 交</el-button>
                <el-button @click="handleClose_companyManagement">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import axios from 'axios'
    import qs from 'qs'
    import arrayQuery from '../../components/arrayQuery.js'
    import CityLinkageReg from "../../components/common/cityLinkageReg.vue"
    import CountriesLink from "../../components/common/countriesLink.vue"
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialogVisible_imgUrl: false,
                companyManagementFlag:[false,false,false,false],
                dialogImageUrl:'',
                dialogcompanyManagement_title:'',
                dialog_companyManagement:false,
                hideUp:false,
                hideUp1:false,
                hideUp2:false,
                hideUp3:false,
                hideBack:false,
                hideBusiness:false,
                imagesUp:[],
                imagesBack:[],
                imagesBusiness:[],
                dialogImageUrl:'',
                dialogImageUrl1:'',
                dialogImageUrl2:'',
                dialogImageUrl3:'',
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisible3: false,
                showBtnImg:true,
                showBtnImg1:true,
                showBtnImg2:true,
                showBtnImg3:true,
                noneBtnImg:false,
                noneBtnImg1:false,
                noneBtnImg2:false,
                noneBtnImg3:false,
                limitCountImg:1,
                limitCountImg1:1,
                limitCountImg2:1,
                limitCountImg3:1,
                imgurl_s:'',
                imgurl_s_contractUrl:'',
                imgurl_s_identityPositiveUrl:'',
                imgurl_s_identityBackUrl:'',
                imgflagmodel:true,
                imgflagmodel1:true,
                imgflagmodel2:true,
                imgflagmodel3:true,
                srcList: [],
                srcList1: [],
                srcList2: [],
                srcList3: [],
                dialogImageUrl_srcList:[],
                imgflag:false,
                imgflag1:false,
                imgflag2:false,
                imgflag3:false,
                imgflagdel:true,
                imgflagdel1:true,
                imgflagdel2:true,
                imgflagdel3:true,
                AccurateAndUnique_companyName:'',
                dataForms:{
                    companyId:'',
                    companyName:'',
                    verify:'',
                    license:'',
                    status:'',
                    companyType:''
                },
                companyManagementForm:{
                    companyId:'',
                    filesId:'',
                    companyName: '',
                    license:'',
                    address:'',
                    identityNo:'',
                    contractNo:'',
                    phone:'',
                    telephone:'',
                    status: '',
                    moduleId:'1',
                    companyType:'',
                    licenseUrl:'',
                    contractUrl:'',
                    identityPositiveUrl:'',
                    identityBackUrl:'',
                    receiveFlag:'0'
                },
                companyManagementFormrules:{
                    companyName: [
                        { required: true, message: '请输入公司全称', trigger: 'blur' }
                    ],
                    license: [
                        { required: true,message: '请输入营业执照号码', trigger: 'blur' }
                    ],
                    status: [
                        { required: true,message: '请选择状态', trigger: 'blur' }
                    ],
                    companyType: [
                        { required: true, message: '请选择公司类别', trigger: 'blur' }
                    ],
                    moduleId: [
                        { required: true, message: '请选择公司模块', trigger: 'blur' }
                    ],
                    receiveFlag: [
                        { required: true, message: '请选择揽收标识', trigger: 'blur' }
                    ]
                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'公司编码',prop:'companyId',width:'120',align:'center',fixed:'left'},
                    {label:'公司全称',prop:'companyName',align:'center',width:'160'},
                    {label:'营业执照号码',prop:'license',align:'center',width:'160'},
                    {label:'审核状态',prop:'verify',align:'center',
                        formatter: (row) => {
                            if (row.verify == '01') {
                                return "未审核";
                            } else if (row.verify == '02') {
                                return "审核通过";
                            } else if (row.verify == '03') {
                                return "审核失败";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'启用状态',prop:'status',align:'center',
                        formatter: (row) => {
                            if (row.status == '1') {
                                return "启用";
                            } else if (row.status == '0') {
                                return "冻结";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'使用类型',prop:'companyType',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.companyType == '1') {
                                return "承运商/司机";
                            } else if (row.companyType == '0') {
                                return "普通用户";
                            } else if (row.companyType == '2') {
                                return "业务管理员";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'揽收标识',prop:'receiveFlag',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.receiveFlag == '0') {
                                return "派车后揽收";
                            } else if (row.receiveFlag == '1') {
                                return "发布后揽收";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'省级名称',prop:'proviceName',align:'center',width:'120'},
                    {label:'市级名称',prop:'cityName',align:'center',width:'120'},
                    {label:'区级名称',prop:'regionName',align:'center',width:'120'},
                    {label:'负责人联系方式',prop:'phone',align:'center',width:'160'},
                    {label:'公司固话',prop:'telephone',align:'center'},
                    {label:'公司地址',prop:'address',align:'center',width:'160'},
                    {label:'合同编号',prop:'contractNo',align:'center'},
                    {label:'法人身份证号',prop:'identityNo',align:'center',width:'120'},
                    {label:'营业执照附件地址',type: "imgLink",prop:'licenseUrl',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.licenseUrl == '' || row.licenseUrl == null || row.licenseUrl == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'合同附件地址',type: "imgLink",prop:'contractUrl',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.contractUrl == '' || row.contractUrl == null || row.contractUrl == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'法人身份证附件地址',type: "imgLink",prop:'identityPositiveUrl',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.identityPositiveUrl == '' || row.identityPositiveUrl == null || row.identityPositiveUrl == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'创建人',prop:'creator',align:'center'},
                    {label:'创建时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'修改人',prop:'modifier',align:'center'},
                    {label:'修改时间',prop:'modifyTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.modifyTime == null){
                                return ''
                            }else {
                                return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    }
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                companyManagementSearchFlag:true,
                companyManagementAddFlag:true,
                companyManagementModifyFlag:true,
                companyManagementDelFlag:true,
                addList: {
                    province: "",
                    city: "",
                    area: ""
                }
            }
        },
        components:{ //引入组件后注册组件
            cesTable,
            Drawer,
            CityLinkageReg,
            CountriesLink
        },
        computed:{
            getUploadUrl(){
                let filePath = `${this.$filePath}`;
                return filePath
            }
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '2_3_2';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let companyManagementSearch = currentPageButtons.find(item => {
                return item.menuId == '2_3_2_1';
            });
            if(companyManagementSearch == undefined){
                _this.companyManagementSearchFlag = false;
            }else {
                _this.companyManagementSearchFlag = true;
            }
            //新增按钮权限
            let companyManagementAdd = currentPageButtons.find(item => {
                return item.menuId == '2_3_2_2';
            });
            if(companyManagementAdd == undefined){
                _this.companyManagementAddFlag = false;
            }else {
                _this.companyManagementAddFlag = true;
            }
            //修改按钮权限
            let companyManagementModify = currentPageButtons.find(item => {
                return item.menuId == '2_3_2_3';
            });
            if(companyManagementModify == undefined){
                _this.companyManagementModifyFlag = false;
            }else {
                _this.companyManagementModifyFlag = true;
            }
            //删除按钮权限
            let companyManagementDel = currentPageButtons.find(item => {
                return item.menuId == '2_3_2_4';
            });
            if(companyManagementDel == undefined){
                _this.companyManagementDelFlag = false;
            }else {
                _this.companyManagementDelFlag = true;
            }
        },
        methods:{
            getCity(data) {
                this.addList.province = data.province;
                this.addList.city = data.city;
                this.addList.area = data.area;
            },
            //---------------------------------------营业执照副本--------------------------------
            handleUpSuccess(response, file, fileList) {
                this.dialogImageUrl = `${this.$imageiPPath}`+response.data;
                this.companyManagementForm.licenseUrl = response.data;
                this.imagesUp = response.data;
                this.hideUp = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag = false;
                this.imgflagmodel = true;
                this.imgflagdel = true;
                this.imgurl_s = `${this.$imageiPPath}`+response.data;
                this.srcList.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError(err, file, fileList) {},
            // 删除图片
            handleUpRemove(file, fileList) {
                this.imagesUp = [];
                this.hideUp = false;
                this.companyManagementForm.licenseUrl = '';
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            // 预览图片
            handleUpPreview(file) {
                this.dialogVisible = true;
            },
            // 上传图片
            handleUpUpload(file, fileList) {
                this.hideUp = true;
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            delimg(){
                this.imgflag = true;
                this.imgflagdel = false;
                this.imgflagmodel = false;
                this.companyManagementForm.licenseUrl = '';
            },
            //---------------------------------------合同附件--------------------------------
            handleUpSuccess1(response, file, fileList) {
                this.dialogImageUrl1 = `${this.$imageiPPath}`+response.data;
                this.companyManagementForm.contractUrl = response.data;
                this.hideUp1 = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag1 = false;
                this.imgflagmodel1 = true;
                this.imgflagdel1 = true;
                this.imgurl_s_contractUrl = `${this.$imageiPPath}`+response.data;
                this.srcList1.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError1(err, file, fileList) {},
            // 删除图片
            handleUpRemove1(file, fileList) {
                this.hideUp1 = false;
                this.companyManagementForm.contractUrl = '';
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            // 预览图片
            handleUpPreview1(file) {
                this.dialogVisible1 = true;
            },
            // 上传图片
            handleUpUpload1(file, fileList) {
                this.hideUp1 = true;
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            delimg1(){
                this.imgflag1 = true;
                this.imgflagdel1 = false;
                this.imgflagmodel1 = false;
                this.companyManagementForm.contractUrl = '';
            },
            //---------------------------------------法人身份证正面--------------------------------
            handleUpSuccess2(response, file, fileList) {
                this.dialogImageUrl2 = `${this.$imageiPPath}`+response.data;
                this.companyManagementForm.identityPositiveUrl = response.data;
                this.hideUp2 = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag2 = false;
                this.imgflagmodel2 = true;
                this.imgflagdel2 = true;
                this.imgurl_s_identityPositiveUrl = `${this.$imageiPPath}`+response.data;
                this.srcList2.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError2(err, file, fileList) {},
            // 删除图片
            handleUpRemove2(file, fileList) {
                this.hideUp2 = false;
                this.companyManagementForm.identityPositiveUrl = '';
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            // 预览图片
            handleUpPreview2(file) {
                this.dialogVisible2 = true;
            },
            // 上传图片
            handleUpUpload2(file, fileList) {
                this.hideUp2 = true;
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            delimg2(){
                this.imgflag2 = true;
                this.imgflagdel2 = false;
                this.imgflagmodel2 = false;
                this.companyManagementForm.identityPositiveUrl = '';
            },
            //---------------------------------------法人身份证反面--------------------------------
            handleUpSuccess3(response, file, fileList) {
                this.dialogImageUrl3 = `${this.$imageiPPath}`+response.data;
                this.companyManagementForm.identityBackUrl = response.data;
                this.hideUp3 = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag3 = false;
                this.imgflagmodel3 = true;
                this.imgflagdel3 = true;
                this.imgurl_s_identityBackUrl = `${this.$imageiPPath}`+response.data;
                this.srcList3.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError3(err, file, fileList) {},
            // 删除图片
            handleUpRemove3(file, fileList) {
                this.hideUp3 = false;
                this.companyManagementForm.identityBackUrl = '';
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            },
            // 预览图片
            handleUpPreview3(file) {
                this.dialogVisible3 = true;
            },
            // 上传图片
            handleUpUpload3(file, fileList) {
                this.hideUp3 = true;
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            },
            delimg3(){
                this.imgflag3 = true;
                this.imgflagdel3 = false;
                this.imgflagmodel3 = false;
                this.companyManagementForm.identityBackUrl = '';
            },
            handleClose_companyManagement(){
                this.dialog_companyManagement = false;
                this.$refs.companyManagementForm.resetFields();
                this.clearImgstarus();
                this.addList.province = '';
                this.addList.city = '';
                this.addList.area = '';
            },
            save_companyManagement(){
                this.$refs["companyManagementForm"].validate(valid => {
                    if (valid) {
                        const data = new URLSearchParams();
                        data.append('companyId', this.companyManagementForm.companyId);
                        data.append('companyName', this.companyManagementForm.companyName);
                        data.append('address', this.companyManagementForm.address);
                        data.append('contractNo', this.companyManagementForm.contractNo);
                        data.append('identityNo', this.companyManagementForm.identityNo);
                        data.append('license', this.companyManagementForm.license);
                        data.append('phone', this.companyManagementForm.phone);
                        data.append('telephone', this.companyManagementForm.telephone);
                        data.append('status', this.companyManagementForm.status);
                        data.append('filesId', this.companyManagementForm.filesId);
                        data.append('moduleId', this.companyManagementForm.moduleId);
                        data.append('proviceAdcode', this.addList.province);
                        data.append('cityAdcode',this.addList.city);
                        data.append('regionAdcode',this.addList.area);
                        data.append('licenseUrl', this.companyManagementForm.licenseUrl);
                        data.append('contractUrl', this.companyManagementForm.contractUrl);
                        data.append('companyType', this.companyManagementForm.companyType);
                        data.append('identityPositiveUrl', this.companyManagementForm.identityPositiveUrl);
                        data.append('identityBackUrl', this.companyManagementForm.identityBackUrl);
                        data.append('receiveFlag', this.companyManagementForm.receiveFlag);
                        if(this.dialogcompanyManagement_title == '新增公司'){
                            //新增
                            if(this.companyManagementForm.licenseUrl == ''){
                                this.$message.error("营业执照必填，请核对后重试!");
                                return false
                            }
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/company/addCompanyInfo`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '新增成功',
                                        type: 'success'
                                    });
                                    this.handleClose_companyManagement();
                                    this.companyManagementSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_companyManagement();
                                }
                            });
                        }else if(this.dialogcompanyManagement_title == '修改公司'){
                            //修改
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/company/updateCompanyInfo`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });
                                    this.handleClose_companyManagement();
                                    this.companyManagementSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_companyManagement();
                                }

                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });

            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.companyManagementSearch();
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.companyManagementSearch();
            },
            handleQuery(){ //查询
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                //快捷查询
                this.loading = true;
                const data = new URLSearchParams();
                data.append('companyName', this.AccurateAndUnique_companyName);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/company/queryUrbanCompanyPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            handleImgClick(imgurl){
                this.dialogImageUrl = `${this.$imageiPPath}`+imgurl.imgurl;
                this.dialogImageUrl_srcList.push(`${this.$imageiPPath}`+imgurl.imgurl);
                this.dialogVisible_imgUrl = true;
            },
            handleCloseImgDialog(){
                this.dialogImageUrl = '';
                this.dialogImageUrl_srcList.length = 0;
                this.dialogVisible_imgUrl = false;
            },
            //查询
            companyManagementSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('companyId', this.dataForms.companyId);
                data.append('companyName', this.dataForms.companyName);
                data.append('license', this.dataForms.license);
                data.append('verify', this.dataForms.verify);
                data.append('status', this.dataForms.status);
                data.append('companyType',this.dataForms.companyType);
                data.append('proviceAdcode',this.addList.province);
                data.append('cityAdcode',this.addList.city);
                data.append('regionAdcode',this.addList.area);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/company/queryUrbanCompanyPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            companyManagementSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.companyManagementSearch();
            },
            clearImgstarus(){
                this.imgflagmodel = false;
                this.imgflagdel = false;
                this.imgflag = true;
                this.srcList.length = 0;

                this.imgflagmodel1 = false;
                this.imgflagdel1 = false;
                this.imgflag1 = true;
                this.srcList1.length = 0;

                this.imgflagmodel2 = false;
                this.imgflagdel2 = false;
                this.imgflag2 = true;
                this.srcList2.length = 0;

                this.imgflagmodel3 = false;
                this.imgflagdel3 = false;
                this.imgflag3 = true;
                this.srcList3.length = 0;
            },
            //新增
            companyManagementAdd(){
                this.dialogcompanyManagement_title = '新增公司';
                this.companyManagementFlag = [false,false,false,false];
                this.clearImgstarus();
                this.dialog_companyManagement = true;
                //this.$router.replace({ path: "/companyManagementadd" });
            },
            nullToStr(data) {
                for (var x in data) {
                    if (data[x] === null) { // 如果是null 把直接内容转为 ''
                        data[x] = '';
                    } else {
                        if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
                            data[x] = data[x].map(z => {
                                return nullToStr(z);
                            });
                        }
                        if(typeof(data[x]) === 'object'){ // 是json 递归继续处理
                            data[x] = nullToStr(data[x])
                        }
                    }
                }
                return data;
            },
            //修改
            companyManagementModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.dialogcompanyManagement_title = '修改公司';
                    this.companyManagementFlag = [true,true,true,true];
                    this.dialog_companyManagement = true;
                    axios.get(`${this.$apiPath}/api/pm/company/queryCompanyById/`+ this.merge[0].companyId).then(res=>{
                        if(res.data.status == true){
                            res.data.status = '1';
                        }else if(res.data.status == false){
                            res.data.status = '0';
                        }
                        res.data.companyType = JSON.stringify(res.data.companyType);
                        res.data.moduleId = JSON.stringify(res.data.moduleId);
                        res.data.receiveFlag = JSON.stringify(res.data.receiveFlag);
                        this.companyManagementForm = res.data;

                        let processedData = this.nullToStr(res.data);
                        this.addList.province = processedData.proviceAdcode;
                        this.addList.city = processedData.cityAdcode;
                        this.addList.area = processedData.regionAdcode;
                        if(res.data.licenseUrl == null || res.data.licenseUrl == ''){
                            this.imgflag = true;
                            this.imgflagmodel = false;
                            this.imgflagdel = false;

                        }else{
                            this.imgflag = false;
                            this.imgflagmodel = true;
                            this.imgflagdel = true;
                            this.imgurl_s = `${this.$imageiPPath}`+res.data.licenseUrl;
                            this.srcList.push(`${this.$imageiPPath}`+res.data.licenseUrl);
                        }
                        if(res.data.contractUrl == null || res.data.contractUrl == ''){
                            this.imgflag1 = true;
                            this.imgflagmodel1 = false;
                            this.imgflagdel1 = false;

                        }else{
                            this.imgflag1 = false;
                            this.imgflagmodel1 = true;
                            this.imgflagdel1 = true;
                            this.imgurl_s_contractUrl = `${this.$imageiPPath}`+res.data.contractUrl;
                            this.srcList1.push(`${this.$imageiPPath}`+res.data.contractUrl);
                        }
                        if(res.data.identityPositiveUrl == null || res.data.identityPositiveUrl == ''){
                            this.imgflag2 = true;
                            this.imgflagmodel2 = false;
                            this.imgflagdel2 = false;

                        }else {
                            this.imgflag2 = false;
                            this.imgflagmodel2 = true;
                            this.imgflagdel2 = true;
                            this.imgurl_s_identityPositiveUrl = `${this.$imageiPPath}`+res.data.identityPositiveUrl;
                            this.srcList2.push(`${this.$imageiPPath}`+res.data.identityPositiveUrl);
                        }
                        if(res.data.identityBackUrl == null || res.data.identityBackUrl == ''){
                            this.imgflag3 = true;
                            this.imgflagmodel3 = false;
                            this.imgflagdel3 = false;
                        }else {
                            this.imgflag3 = false;
                            this.imgflagmodel3 = true;
                            this.imgflagdel3 = true;
                            this.imgurl_s_identityBackUrl = `${this.$imageiPPath}`+res.data.identityBackUrl;
                            this.srcList3.push(`${this.$imageiPPath}`+res.data.identityBackUrl);
                        }
                    })
                }
            },
            //删除
            companyManagementDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对公司进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/pm/company/deleteCompanyInfo/`+ this.merge[0].companyId).then(res=>{
                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });

                                this.companyManagementSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });

                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            handleClose() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.companyManagementSearch_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                this.addList.province = '';
                this.addList.city = '';
                this.addList.area = '';
                clearTimeout(this.timer);
            }
        }
    }
</script>
<style>
    .Reditem .el-form-item__label{
        color: #FF0000;
    }
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>

    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
